import { gql, useApolloClient } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { Device } from "../../../Models/models";
import localized from "../../../en.json";
import { BreadCrumbContext } from "../../../store/breadcrumb-context";
import ShowSnackbar from "../../CustomizedSnackbar/ShowSnackbar";
import DeviceMonitor from "../DeviceMonitor/DeviceMonitor";
import { DeviceDetailsLeftPanel } from "./DeviceDetailsLeftPanel";
import SoftwaresOnDevice from "./SoftwaresOnDevice/SoftwaresOnDevice";
import { Chart } from "../DataVisualization/Charts/Chart";
import EventLog from "../EventLog/EventLog";

export const GET_DEVICE_DETAIL = gql`
  query ($deviceId: ID!) {
    getDeviceById(deviceId: $deviceId) {
      id
      name
      connectionStatus
      macAddress
      registeredAt
      ipAddress
      portNumber
      deviceUsername
      devicePassword
      latitude
      longitude
      firmwareVersion
      createdAt
      updatedAt
      statusReason
      provisionStatus
      serialId
      type
      deviceStatus
      deviceState
      assetsInDevice {
        id
        name
      }
    }
  }
`;
export const DeviceDetails = () => {
  let { deviceId } = useParams();
  const client = useApolloClient();
  const [deviceDetails, setDeviceDetails] = useState<Device>();
  const [currentDate] = useState<Date>(new Date());
  const [calcDate] = useState<Date>(new Date());
  const [startTime] = useState<Date>(
    new Date(calcDate.setDate(currentDate.getDate() - 1))
  );
  const [endTime] = useState<Date>(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const [signalsSelected, setSignalsSelected] = useState<string[]>([
    "framework/metrics/system/cpu-utilisation-percent",
  ]);

  const location = useLocation();
  const assetDetails = location.state;
  const breadCrumbContext = useContext(BreadCrumbContext);

  const getDeviceById = async () => {
    client
      .query({
        query: GET_DEVICE_DETAIL,
        variables: {
          deviceId: deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setDeviceDetails(response.data?.getDeviceById);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-device-detail"],
          false,
          enqueueSnackbar
        );
      });
  };

  useEffect(() => {
    getDeviceById();
    const intervalId = setInterval(getDeviceById, 30000);

    return () => clearInterval(intervalId);
  }, [deviceId, client, enqueueSnackbar]);

  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Monitor",
        link: "/dashboard/",
      },
      {
        name: assetDetails?.name,
        link: `/asset-monitoring/${assetDetails?.id}/projectsld`,
      },
      {
        name: deviceDetails?.name,
        link: "/dashboard/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location, deviceDetails]);

  return (
    <Stack
      flexDirection="row"
      id="test1234"
      sx={{ padding: "24px 32px", gap: "32px" }}
    >
      <DeviceDetailsLeftPanel deviceDetails={deviceDetails} />
      <Routes>
        <Route
          path="/softwareondevice/*"
          element={
            deviceDetails?.deviceStatus === "Configured" ? (
              <SoftwaresOnDevice deviceId={deviceDetails?.id} />
            ) : (
              <Typography
                variant="overline"
                sx={{
                  color: "#959595",
                  textTransform: "none",
                  fontWeight: "700",
                  marginTop: "8px",
                }}
              >
                {localized["configure-device-first"]}
              </Typography>
            )
          }
        />
        <Route
          path="/devicemonitoring"
          element={
            <DeviceMonitor
              deviceId={deviceId}
              setSignalsSelected={setSignalsSelected}
            />
          }
        />
        <Route
          path="/datavisualization"
          element={
            <Chart
              deviceId={deviceId}
              signalsSelected={signalsSelected}
              startTime={startTime}
              endTime={endTime}
            />
          }
        />
        <Route path="/eventLog" element={<EventLog />} />
      </Routes>
    </Stack>
  );
};

import { gql, useApolloClient } from "@apollo/client";
import { Stack, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigurationObject, Order } from "../../../../../../Models/models";
import localized from "../../../../../../en.json";
import {
  styled,
  tooltipClasses,
  TooltipProps
} from "@mui/material";
import {
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  PowerIcon,
  PowerOffIcon,
} from "../../../../../../theme/Icons/IshIcons";
import { ConfirmationDialog } from "../../../../../../util/ConfirmationDialog";
import { tableRowStyle } from "../../../../../../util/TableStyleUtil";
import {
  calculateEmptyRows,
  getComparator,
  handlePageChange,
  handleRowsPerPageChange,
  stableSort,
} from "../../../../../../util/TableUtil";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import { LightTooltip } from "../../../../CustomerManagement/AssignEdgeDevice/AssignEdgeDeviceDialog";
import {
  getEmptyRow,
  getPagination,
  getSearchbar,
  getTableCell,
  HeadCell,
} from "../../../EnergyAssets/AssetsTable/AssetsTable";

interface ConfigData {
  id: number;
  sessionName: string;
  username: string;
  endpointURL: string;
  state: string;
  status: string;
  errorMessage: string;
}
export const DELETE_CONFIGURATION_BY_ID = gql`
  mutation ($protocolConfigurationID: Int!) {
    deleteConfigurationById(protocolConfigurationID: $protocolConfigurationID)
  }
`;
const headCells: readonly HeadCell<ConfigData>[] = [
  {
    id: "sessionName",
    label: localized["session-name"],
  },
  {
    id: "username",
    label: localized["username"],
  },
  {
    id: "endpointURL",
    label: localized["endpoint-url"],
  },
  {
    id: "state",
    label: localized["state"],
  },
];

export const MANAGE_PROTOCOL_CONNECTION = gql`
  mutation ($protocolConfigurationID: Int!, $connect: Boolean!) {
    manageProtocolSession(
      protocolConfigurationID: $protocolConfigurationID
      connect: $connect
    )
  }
`;

export const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)", 
    boxShadow: theme.shadows[2], 
    padding: 10,
    fontSize: 16,
    border: "1px solid #DA1E28",
  }
}));


export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ConfigData
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
type PropsType = {
  configDataList: ConfigurationObject[];
  setIsRefetchRequired: Function;
};

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof ConfigData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return getTableCell<ConfigData>(
            headCell,
            order,
            orderBy,
            createSortHandler
          );
        })}
        <TableCell key="deleteCol" align="left" padding="none" />
      </TableRow>
    </TableHead>
  );
}

const CommunicationProtocolsTableView = (props: PropsType) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof ConfigData>("id");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rows, setRows] = useState<ConfigData[]>([]);
  const [filteredRows, setFilteredRows] = useState<ConfigData[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<ConfigData | undefined>(
    undefined
  );
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [
    isDeleteConfigConfirmationDialogOpen,
    setIsDeleteConfigConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { siteId } = useParams();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    setFilteredRows(
      rows.filter((row) =>
        Object.values(row).some((val) =>
          val?.toString().toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  };
  const onDeleteCustomerConfirmHandler = () => {
    client
      .mutate({
        mutation: DELETE_CONFIGURATION_BY_ID,
        variables: {
          protocolConfigurationID: selectedConfig?.id,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setIsDeleteConfigConfirmationDialogOpen(false);
        props.setIsRefetchRequired(true);
        ShowSnackbar(
          localized["delete-configuration-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        setIsDeleteConfigConfirmationDialogOpen(false);
        ShowSnackbar(
          localized["delete-configuration-failed"],
          false,
          enqueueSnackbar
        );
      });
  };
  const onConfigDeleteHandler = (config: ConfigData) => {
    setIsDeleteConfigConfirmationDialogOpen(true);
    if (config.state === "CONNECTED") {
      setIsWarning(true);
      setTitle(localized["cannot-delete-configuration"]);
      setDescription(localized["delete-configuraion-warning"]);
    } else {
      setIsWarning(false);
      setTitle(localized["delete-configuration"]);
      setDescription(localized["configuration-delete-confirmation"]);
    }
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ConfigData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  useEffect(() => {
    const convertedData = transformObjectsArray(props.configDataList);
    setRows(convertedData);
    setFilteredRows(convertedData);
  }, [props.configDataList]);

  const manageProtocolSession = (
    protocolConfigurationID: number,
    connect: boolean
  ) => {
    client
      .mutate({
        mutation: MANAGE_PROTOCOL_CONNECTION,
        variables: {
          protocolConfigurationID: protocolConfigurationID,
          connect: connect,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        ShowSnackbar(
          localized["device-state-update-soon-msg"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-update-device-state"],
          false,
          enqueueSnackbar
        );
      });
  };

  const handleConnectionClick = (state: string, id: number) => {
    if (state === "DISCONNECTED") {
      manageProtocolSession(id, true);
    } else {
      manageProtocolSession(id, false);
    }
  };

  const handleEditProtocol = (row: any) => {
    navigate(
      `/engineering/site/${siteId}/edgedevices/device/${deviceId}/editprotocol/${row.protocolId}/${row.id}`,
      {
        state: row.protocolName,
      }
    );
  }

  return (
    <>
      {rows?.length > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          alignItems="flex-start"
          sx={{
            height: "100%"
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignSelf="stretch"
          >
            {getSearchbar(searchQuery, handleSearchChange)}
          </Box>
          <Box
            sx={{
              width: "99%",
              maxHeight: "calc(100vh - 545px)",
              border: "1px solid #DEDEDE",
              borderRadius: "8px",
              overflow: "auto",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: "8px",
                maxHeight: "100%",
                overflow: "auto",
                scrollbarWidth: "thin",
              }}
            >
              <Table stickyHeader aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredRows.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{
                            ...tableRowStyle,
                            ...(row.status === "ERROR" && {
                              background: '#DA1E281A',
                            }),
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          <Stack
                            direction={"row"}
                            gap="4px"
                            alignItems={"center"}
                          >
                            {row.sessionName}
                            {row.status === 'ERROR' && row.errorMessage && (
                              <ErrorTooltip title={row.errorMessage} 
                                placement="top"
                                sx={{ cursor: "pointer", }}>
                                <Box
                                  width={"20px"}
                                  height={"20px"}
                                >
                                  <ErrorIcon
                                    sx={{
                                      width: "29px",
                                      height: "29px",
                                    }}
                                  />
                                </Box>
                              </ErrorTooltip>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          {row.username}
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          {row.endpointURL}
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          <Stack
                            direction={"row"}
                            gap="4px"
                            alignItems={"center"}
                          >
                            {row.state !== "NA" && (
                              <Box
                                width={"12px"}
                                height={"12px"}
                                bgcolor={
                                  row.state === "CONNECTED"
                                    ? "green"
                                    : "#DA1E28"
                                }
                                borderRadius={"20px"}
                              ></Box>
                            )}
                            {row.state}
                          </Stack>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "18px !important",
                            paddingRight: "0px !important",
                          }}
                        >
                           <Stack direction="row" spacing={1} alignItems="center">
                          <IconButton
                            data-testid={`connect-${row.id}`}
                            onClick={() =>
                              handleConnectionClick(row.state, row.id)
                            }
                            sx={{
                              padding: "0px",
                              marginRight: "12px",
                              "&.Mui-disabled": { cursor: "not-allowed" },
                            }}
                            disabled={row.state === "NA"}
                          >
                            {row.state === "DISCONNECTED" ? (
                              <PowerIcon fill={"black"} />
                            ) : (
                              <PowerOffIcon
                                fill={row.state === "NA" ? "#ADA9C2" : "black"}
                              />
                            )}
                          </IconButton>
                          {row.state === "CONNECTED" ?
                            <LightTooltip
                              placement="top"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    maxWidth: '200px',
                                    padding: "8px",
                                    border: "1px solid #EAEAEA"
                                  },
                                }
                              }}
                              title={localized['disabled-edit-tooltip-title']}>
                              <Box data-testid={`disabled-edit-tooltip-${row.id}`}>
                                <IconButton
                                  data-testid={`edit-asset-${row.id}`}
                                  disabled
                                  sx={{ padding: "0px", marginRight: "12px" }}
                                  onClick={() => { handleEditProtocol(row) }}
                                >
                                  <EditIcon
                                    sx={{
                                      path: { fill: "rgb(173, 169, 194)" },
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            </LightTooltip> :
                            <IconButton
                              data-testid={`edit-asset-${row.id}`}
                              disabled={!(row.state === "NA" || row.state === "DISCONNECTED")}
                              sx={{ padding: "0px", marginRight: "12px" }}
                              onClick={() => { handleEditProtocol(row) }}
                            >
                              <EditIcon
                                sx={{
                                  path: { fill: !(row.state === "NA" || row.state === "DISCONNECTED") ? "rgb(173, 169, 194)" : "#000" },
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </IconButton>
                          }
                          <IconButton
                            data-testid={`delete-single-config-${row.id}`}
                            onClick={() => {
                              setSelectedConfig(row);
                              onConfigDeleteHandler(row);
                            }}
                            sx={{ padding: "0px" }}
                          >
                            <DeleteIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {calculateEmptyRows(page, rowsPerPage, filteredRows.length) >
                    0 &&
                    getEmptyRow(
                      calculateEmptyRows(page, rowsPerPage, filteredRows.length)
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {getPagination(
            visibleRows,
            filteredRows,
            rowsPerPage,
            page,
            handlePageChange(setPage),
            handleRowsPerPageChange(setRowsPerPage, setPage)
          )}
        </Box>
      ) : (
        <Typography
          variant="overline"
          sx={{ color: "#959595", fontWeight: "700", textTransform: "none" }}
        >
          {localized["start-by-creating-a-new-configuration"]}
        </Typography>
      )}
      <ConfirmationDialog
        isDialogOpen={isDeleteConfigConfirmationDialogOpen}
        handleCloseDialog={setIsDeleteConfigConfirmationDialogOpen}
        title={title}
        description={description}
        leftButtonText={localized["cancel-btn"]}
        rightButtonText={localized["yes-delete"]}
        leftButtonClickHandler={setIsDeleteConfigConfirmationDialogOpen}
        rightButtonClickHandler={onDeleteCustomerConfirmHandler}
        isWarning={isWarning}
      />
    </>
  );
};
export default CommunicationProtocolsTableView;
function transformObjectsArray(
  inputArray: ConfigurationObject[]
): ConfigData[] {
  return inputArray.map((input) => ({
    id: Number(input.id),
    sessionName: input.parameters.name,
    username: input.parameters.username,
    endpointURL: input.parameters.endpointUrl,
    state: input.state,
    status: input.status,
    errorMessage: input.errorMessage,
    protocolId: input.communicationProtocol.id,
    protocolName: input.communicationProtocol.name
  }));
}

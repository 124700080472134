import {
  Divider,
  Icon,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type menuListItem = {
  name: string;
  icon: JSX.Element;
};

type Props = {
  engineeringMenuList: menuListItem[];
  ManagementMenuList: menuListItem[];
  displayText: string;
};

export const MenuList = (props: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | undefined>(
    ""
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/")[4];
    setSelectedMenuItem(path);
  }, []);
  const onMenuItemClickHandler = (menuItemName: string) => {
    setSelectedMenuItem(menuItemName);
    navigate(menuItemName.toLowerCase().replaceAll(" ", ""));
  };
  const renderMenuList = (menuList: any) => {
    return (
      <List
        sx={{
          overflowY: "auto",
          paddingBottom: "0px !important",
          paddingTop: "0px !important",
          width: "100%",
          minWidth: "240px",
          borderRadius: "8px 8px 8px 8px",
          background: "#ffffff",
        }}
      >
        {menuList.map((menuitem: any) => {
          return (
            <>
              <ListItem
                key={menuitem.name}
                data-testid={`item-${menuitem.name}`}
                sx={{
                  height: "64px",
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  borderLeft:
                    selectedMenuItem?.toLowerCase().replaceAll(" ", "") ===
                    menuitem.name?.toLowerCase().replaceAll(" ", "")
                      ? "4px solid #8A00E5"
                      : "4px solid #ffffff",
                  "&:hover": {
                    backgroundColor: "#EAEAEA",
                    borderLeft:
                      selectedMenuItem?.toLowerCase().replaceAll(" ", "") ===
                      menuitem.name?.toLowerCase().replaceAll(" ", "")
                        ? "4px solid #8A00E5"
                        : "4px solid #EAEAEA",
                  },
                }}
                onClick={() => onMenuItemClickHandler(menuitem.name)}
              >
                <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
                  <Icon
                    sx={{
                      ".MuiSvgIcon-root": {
                        height: "20px",
                        width: "20px",
                      },
                    }}
                  >
                    {menuitem.icon}
                  </Icon>
                  <Typography variant="h5" fontWeight="400">
                    {menuitem.name}
                  </Typography>
                </Stack>
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    );
  };
  return (
    <Stack>
      <Typography variant="caption" lineHeight={"17px"} marginBottom={"8px"}>
        Engineering
      </Typography>
      {renderMenuList(props.engineeringMenuList)}
      <Typography
        variant="caption"
        lineHeight={"17px"}
        marginBottom={"8px"}
        marginTop={"40px"}
      >
        {props.displayText}
      </Typography>
      {renderMenuList(props.ManagementMenuList)}
    </Stack>
  );
};

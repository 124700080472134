import { gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ShowSnackbar from "../components/CustomizedSnackbar/ShowSnackbar";
import localized from "../en.json";
import { Software } from "../Models/models";

export const GET_ALL_SOFTWARES = gql`
  query {
    getSoftwareForStore {
      id
      name
      description
      version
      uploadedAt
      uploadedBy
      status
      fileSize
      compatibleDeviceType
      cpuArchitecture
      customers {
      id
      name
      }
    }
  }
`;

export const SoftwareContext = createContext<ContextType | undefined>(
  undefined
);

interface ContextType {
  softwares: Software[] | undefined;
  softwareListChangeHandler: Function;
}
export const SoftwareContextProvider = (props: any) => {
  const [softwares, setSoftwares] = useState<Software[] | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const fetchAllSoftwares = useCallback(() => {
    client
      .query({
        query: GET_ALL_SOFTWARES,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        setSoftwares(response.data.getSoftwareForStore);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-software"],
          false,
          enqueueSnackbar
        );
      });
  }, [client, enqueueSnackbar]);

  const softwareListChangeHandler = useCallback(() => {
    fetchAllSoftwares();
  }, [fetchAllSoftwares]);

  useEffect(() => {
    fetchAllSoftwares();
  }, [fetchAllSoftwares]);

  const contextValue = useMemo(() => {
    return {
      softwares: softwares,
      softwareListChangeHandler: softwareListChangeHandler,
    };
  }, [softwares, softwareListChangeHandler]);
  return (
    <SoftwareContext.Provider value={contextValue}>
      {props.children}
    </SoftwareContext.Provider>
  );
};

export default SoftwareContextProvider;

import { useApolloClient } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Tabs, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import localized from "../../../../../../en.json";
import { RenderTab, TabPanel } from "../../../../../../util/TabPanelProps";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import { GET_DEVICE_DETAIL } from "../../CommunicationProtocolForm/CommunicationProtocolForm";
import CommunicationProtocolTab from "./CommunicationProtocolTab";
import EdgeDeviceConfigurationForm from "./EdgeDeviceConfigurationForm";
import SignalMappingTree from "./SignalMappingTree";

const EdgeDevicesTab = () => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [deviceName,setDeviceName] = useState();
  const location = useLocation();

  // Determine the current tab based on the current route
  const getTabValue = () => {
    const match = location.pathname.match(/\/tab(\d+)$/);
    if (match) {
      return parseInt(match[1], 10) - 1; // Convert 'tab1' to 0, 'tab2' to 1, etc.
    }
    return 0;
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const basePath = location.pathname.replace(/\/tab\d+$/, ""); // Remove the existing tab part
    navigate(`${basePath}/tab${newValue + 1}`); // Navigate to the new tab
  };
  const [tabValue, setTabValue] = useState(getTabValue());
  const [refreshDeviceName, setRefreshDeviceName] = useState(false);

  const { siteId, deviceId } = useParams<{
    siteId: string;
    deviceId: string;
  }>();

  useEffect(() => {
    const getDeviceDetailById = async () => {
      client
        .query({
          query: GET_DEVICE_DETAIL,
          variables: {
            deviceId: deviceId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          setDeviceName(response.data?.getDeviceById.name);  
          setRefreshDeviceName(false);
        })
        .catch(() => {
          ShowSnackbar(
            localized["failed-to-fetch-device-detail"],
            false,
            enqueueSnackbar
          );
        });
    };
    getDeviceDetailById();
  }, [deviceId, client, location,refreshDeviceName]);
  return (
    <Box display="flex" flexDirection="column" width="100%">
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        margin: "24px 32px",
      }}
    >
  
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          color: "#0D0D0D",
        }}
      >
        <IconButton data-testid="backToDeviceTable" onClick={()=>{navigate(`/engineering/site/${siteId}/edgedevices`)}}>
          <ArrowBackIcon sx={{height:"24px",width:"24px",color:"#8A00E5"}}/>
        </IconButton>
        <Typography marginLeft={"24px"} fontSize={"22px"} variant="h6">{deviceName || deviceId}</Typography>
      </Box>

      <Box>
        <Tabs
          id="tabstest"
          sx={{
            "& .MuiTabs-indicator": { borderBottom: "4px solid #8A00E5" },
            "& .MuiTab-root": { padding: "16px 24px", minHeight: "54px" },
            "& .MuiTab-root.Mui-selected": { padding: "16px 24px" },
          }}
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="edge devices tabs"
        >
          {RenderTab(localized["configuration-tab"], 0)}
          {RenderTab(localized["signal-mapping-tab"], 1)}
          {RenderTab(localized["Communication-protocols-tab"], 2)}
        </Tabs>
      </Box>
    </Box>


    <Box margin={"32px"}>
      <TabPanel value={tabValue} index={0}>
        <EdgeDeviceConfigurationForm
        setRefreshDeviceName={setRefreshDeviceName}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SignalMappingTree
        
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CommunicationProtocolTab
        
        />
      </TabPanel>
    </Box>
  </Box>
);
};

export default EdgeDevicesTab;
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WifiIcon from '@mui/icons-material/Wifi';
import { Box, Button, ButtonGroup, Chip, Stack, Typography } from '@mui/material';
import { CustomerEdgeDevicesIcon, DisconnectedIcon } from '../../../theme/Icons/IshIcons';
import localized from '../../../en.json';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Device } from '../../../Models/models';

const deviceMonitoring = {
    id: 1,
    name: localized['device-monitoring-btn']
}
const dataVisualization = {
    id: 2,
    name: localized['data-visualization-btn']
}
const softwareOnDevice = {
    id: 3,
    name: localized['software-on-device-btn']
}
const eventLog = {
    id: 4,
    name: localized['event-log-btn']
}

const devicetabs = [deviceMonitoring, dataVisualization, softwareOnDevice, eventLog];

interface PropTypes {
    deviceDetails: Device | undefined;
}
export const DeviceDetailsLeftPanel = (props: PropTypes) => {
    const { deviceDetails } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const assetDetails = location.state;
    const [selectedMenuId, setSelectedMenuId] = useState(1);
    useEffect(() => {
        const path = location.pathname.split("/")[3];
        const selectedTab = devicetabs.find(tab => tab.name.toLowerCase().replace(/\s+/g, '').includes(path.toLowerCase()));

        if (selectedTab) {
            setSelectedMenuId(selectedTab.id);
        }
    }, [location]);

    const handleMenuClick = (event: any, menu: any) => {
        setSelectedMenuId(menu.id);
        navigate(menu.name.toLowerCase().replace(/\s+/g, ''), {
            state: assetDetails,
        });
    };
    const onClickAsset = (event: any, id: string) => {
        navigate(`/asset-monitoring/${assetDetails?.id}/projectsld`);
    };
    return (
        <Stack sx={{ gap: "32px", height: "calc(100vh - 200px)" }}>
            <Stack
                sx={{
                    background: "#fff",
                    width: "315px",
                    borderRadius: "8px",
                    gap: "24px"
                }}>
                <Box display="flex" sx={{ padding: "24px", paddingBottom: "0px", justifyContent: "space-between" }}>
                    <Typography variant='h4' sx={{ fontWeight: "400", color: "#0D0D0D" }}>{deviceDetails?.name}</Typography>
                    <Box display="flex" sx={{ gap: "2px" }}>
                        <Chip
                            label={1}
                            icon={<ErrorOutlineOutlinedIcon sx={{ color: '#fff !important', width: '16px', height: '16px' }} />}
                            sx={{
                                gap: "4px",
                                height: "30px",
                                color: "#fff",
                                background: "#DA1E28",
                                padding: "4px 8px",
                                ".MuiChip-label": {
                                    padding: "0px", fontSize: "12px", fontWeight: "700"
                                },
                                ".MuiChip-icon": {
                                    margin: "0px"
                                }
                            }}
                        />
                        <Chip
                            label={2}
                            icon={<ErrorOutlineOutlinedIcon sx={{ color: '#000 !important', width: '16px', height: '16px' }} />}
                            sx={{
                                gap: "4px",
                                height: "30px",
                                color: "#000",
                                background: "#E2D000",
                                padding: "4px 8px",
                                ".MuiChip-label": {
                                    padding: "0px", fontSize: "12px", fontWeight: "700"
                                },
                                ".MuiChip-icon": {
                                    margin: "0px"
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" sx={{ padding: "0px 24px", justifyContent: "space-between", gap: "4px" }}>
                    <Box display="flex" sx={{ gap: "10px" }}>
                        <Typography variant="h5" width="100px">{localized['status']}:</Typography>
                        <>
                        {deviceDetails?.connectionStatus === "connected" ? (
                            <WifiIcon
                                sx={{
                                color: "#007942", 
                                width: "20px",
                                height: "20px",
                              }}
                                />
                            ) : (
                            <DisconnectedIcon
                                sx={{
                                color: "rgba(0, 0, 0, 0.6)", 
                                width: "20px",
                                height: "20px",
                                    }}
                                />
                               )}                            
                    <Typography variant="body1" fontSize="16px">{deviceDetails?.deviceState}</Typography>
                        </>
                    </Box>
                    <Box display="flex" sx={{ gap: "10px" }}>
                        <Typography variant="h5" width="100px">{localized['project']}:</Typography>
                        <Typography variant="body1" fontSize="16px">{assetDetails?.site.project.name}</Typography>
                    </Box>
                </Box>
                <ButtonGroup orientation="vertical" aria-label="Vertical button group" sx={{ display: "flex", background: "#ffffff", gap: "1px" }}>
                    {devicetabs.map((item) => (
                        <Button
                            key={item.id}
                            onClick={(event) => handleMenuClick(event, item)}
                            data-testid={item?.name}
                            sx={{
                                display: "flex",
                                gap: "2px",
                                color: "#1B1534",
                                textTransform: "none",
                                width: "100%",
                                height: "56px",
                                justifyContent: "space-between",
                                background: "#fff !important",
                                border: "1px solid #EAEAEA",
                                borderBottom: "none !important",
                                borderLeft: (item?.id === selectedMenuId) ? "4px solid #8A00E5!important" : "none",
                                borderRadius: "0px !important",
                                ":hover, :focus, :active": {
                                    border: "1px solid #EAEAEA !important",
                                    background: (item?.id === selectedMenuId) ? "#fff !important" : "#EAEAEA !important",
                                    borderLeft: (item?.id === selectedMenuId) ? "4px solid #8A00E5!important" : "1px solid #EAEAEA",
                                    borderBottom: "none !important",
                                }
                            }}>
                            <Typography variant='h5' sx={{ color: "#0D0D0D", fontWeight: "400", textAlign: "justify" }}>{item?.name}</Typography>
                            <KeyboardArrowRightIcon />
                        </Button>
                    ))}
                </ButtonGroup>
            </Stack>
            <Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
                <Typography variant="overline" sx={{ color: "#0D0D0D", textTransform: "none" }}>{localized['edge-devices-connected']}</Typography>
                <Stack
                    sx={{
                        background: "#fff",
                        width: "315px",
                        borderRadius: "8px",
                        gap: "8px"
                    }}>
                    <ButtonGroup orientation="vertical" aria-label="Vertical button group" sx={{ display: "flex", overflowY: "auto", height: "auto", maxHeight: "57vh", background: "#ffffff", gap: "1px", borderRadius: "8px" }}>
                        {deviceDetails?.assetsInDevice?.map((asset) => (
                            <Button
                                key={asset.id}
                                onClick={(event) => onClickAsset(event, asset?.id)}
                                data-testid={asset?.name}
                                sx={{
                                    display: "flex",
                                    gap: "2px",
                                    color: "#1B1534",
                                    textTransform: "none",
                                    width: "100%",
                                    height: "56px",
                                    justifyContent: "space-between",
                                    background: "#fff !important",
                                    border: "1px solid #EAEAEA",
                                    borderBottom: "none !important",
                                    borderRadius: "0px !important",
                                    ":hover, :focus, :active": {
                                        border: "1px solid #EAEAEA !important",
                                        borderBottom: "none !important",
                                    }
                                }}>
                                <Box display="flex" gap="16px" alignItems="center">
                                    <CustomerEdgeDevicesIcon sx={{
                                        path: { fill: "#0D0D0D" }, width: "16px", height: "16px"
                                    }} />
                                    <Typography variant='h5' sx={{ color: "#0D0D0D", fontWeight: "400", textAlign: "justify" }}>{asset?.name}</Typography>
                                </Box>
                                <ArrowOutwardIcon sx={{ width: "20px", height: "20px" }} />
                            </Button>
                        ))}
                    </ButtonGroup>
                </Stack>
            </Box>
        </Stack>
    )
}

import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import CommunicationProtocolForm from "./CommunicationProtocolForm/CommunicationProtocolForm";
import EdgeDevicesTable from "./EdgeDeviceTableAndTab/EdgeDevicesTable/EdgeDevicesTable";
import EdgeDevicesTab from "./EdgeDeviceTableAndTab/EdgeDevicesTabs/EdgeDevicesTabs";

interface PropTypes {
  siteId: number;
}
const EdgeDevices = (props: PropTypes) => {
  const { siteId } = props;
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Box margin={"32px"}>
            <EdgeDevicesTable siteId={siteId} />
          </Box>
        }
      />
      <Route
        path="/device/:deviceId/newprotocol/:protocolId"
        element={<CommunicationProtocolForm />}
      />
      <Route
        path="/device/:deviceId/editprotocol/:protocolId/:protocolConfigId"
        element={<CommunicationProtocolForm />}
      />
      <Route
        path="/device/:deviceId/tab1"
        element={
          <EdgeDevicesTab />
        }
      />
      <Route
        path="/device/:deviceId/tab2"
        element={
          <EdgeDevicesTab />
        }
      />
      <Route
        path="/device/:deviceId/tab3"
        element={
          <EdgeDevicesTab />
        }
      />
    </Routes>
  );
};

export default EdgeDevices;

import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { RedirectIcon } from "../../../../../theme/Icons/IshIcons";

type PropsType = {
    isDialogOpen: boolean;
    handleCloseDialog: Function;
    setIsAdminLinksDialogOpen: Function;
};
export const AdminLinksDialog = (props: PropsType) => {
    return (
        <Dialog
            onClose={() => props.handleCloseDialog(false)}
            aria-labelledby="customized-dialog-title"
            open={props.isDialogOpen}
            fullWidth
            maxWidth="xs"
            sx={{
                border: "1px solid #EAEAEA", borderRadius: "8px", "& .MuiDialog-paper": {
                    width: "396px", // Set a custom width
                    maxWidth: "none", // Prevent maxWidth constraints
                },
            }}
        >
            <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
                <DialogTitle
                    sx={{ m: 0, fontWeight: "400", fontSize: "28px", lineHeight: "36px" }}
                    id="customized-dialog-title"
                >
                    <Typography fontSize={"28px"} lineHeight={"36px"} fontWeight={400}>
                        Admin Links
                    </Typography>
                </DialogTitle>
                <IconButton
                    data-testid="cancelButton"
                    aria-label="close"
                    onClick={() => props.handleCloseDialog(false)}
                    sx={{
                        position: "absolute",
                        right: 32,
                        top: 32,
                        color: "#0D0D0D",
                        width: "32px",
                        height: "32px",
                    }}
                >
                    <CloseIcon
                        style={{
                            width: "32px",
                            height: "32px",
                        }}
                    />
                </IconButton>
            </Box>
            <DialogContent sx={{ padding: "32px " }}>

                <Stack data-testid="certificate-manager-links" onClick={()=>{props.setIsAdminLinksDialogOpen(false)}} width={"330px"} height={"60px"} direction={"row"} alignItems="center" gap="16px" border={"1px solid #DEDEDE"} borderRadius={"5px 5px 0 0"}>
                    <RedirectIcon sx={{ marginLeft: "16px" }} />
                    <Typography variant="body2" lineHeight={"28px"}>Certificate Manager</Typography>
                </Stack>

            </DialogContent>

        </Dialog>
    );
};
